<template>
    <div class="zt-page-content" style="min-width:1320px;">
        <div v-if="!tagFlag" class="zt-page-head">渠道白名单</div>
        <div v-if="!tagFlag" class="zt-block" style="height: calc(100% - 146px)">
            <div class="thead flex">
                <!-- <div class="input-block flex">
                    <el-input v-model="searchValue" placeholder="输入公司名称" 
                        prefix-icon="el-icon-search"></el-input>
                    <el-button @click="search">搜索</el-button>
                </div> -->
            </div>
            <div style="height: calc(100% - 56px);overflow-y: auto">
                <div class="list flex">
                    <div class="list-item" v-for="(item,idx) in agentList" :key="idx">
                        <div class="top">
                            <div class="compName">{{item.factory.name}}</div>
                            <div class="group">
                                {{item.agency_group.name}}
                                <!-- <div class="group-item" v-for="(tag,idx) in item.agency_group" :key="idx">
                                    {{tag.name}}
                                </div> -->
                            </div>
                            <div class="state">状态：
                                <span v-if="item.stat==1" style="color:#F7B500;">审核中</span>
                                <span v-if="item.stat==2" style="color:#2BBAAE;">已通过</span>
                                <span v-if="item.stat==3" style="color:#F66F6A;">已拒绝</span>
                            </div>
                        </div>
                        <div class="bottom flex flex-align-center">
                            <div class="flex-1"></div>
                            <el-button v-if="item.stat==2" @click="gotoGroup(item)" size="mini" type="primary" plain>展开</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="width:1280px;margin:0 auto;padding:12px 10px" class="flex">
                <el-pagination background layout="prev, pager, next" 
                    :current-page.sync="queryOpt.page" 
                    :page-size="queryOpt.pagesize" :total="total">
                </el-pagination>
            </div>
        </div>

        <GroupGoods v-if="tagFlag" :tagId="tagId" v-model="tagList" @back="hide"></GroupGoods>
        
    </div>
</template>
<script>
    import { listAgency } from '@/service/white';
    import GroupGoods from './GroupGoods'
    export default {
        components: { GroupGoods },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                // 分组下的代理商
                queryOpt:{
                    group_id: null,
                    page:1,
                    pagesize:15,
                },
                total:0,
                agentList:[],
                searchValue:"",
                tagFlag:false,
                tagList: [],
                tagId: 0,
            }
        },
        created() {
            this.tag_list();
        },
        mounted() {},
        methods: {
            gotoGroup(item) {
                this.tagId = item.id.toString();
                // let newTabName = ++this.tabIndex + '';
                if (this.tagList.findIndex(e => e && e.id == item.id) == -1) {
                    this.tagList.push({
                        id:item.id.toString(),
                        factory_id:item.factory.id,
                        factory_name:item.factory.name,
                        group_id:item.agency_group.id,
                        group_name:item.agency_group.name,
                    })
                }
                // this.tagFlag = false;
                // this.$nextTick(()=>{
                    
                // })
                this.tagFlag = true;
                console.log(this.tagList);
            },
            hide() {
                this.tagFlag = false;
            },
            search() {

            },
            tag_list() {
                listAgency().then(rst => {
                    if (rst && rst.length>0) {
                        this.agentList = rst;
                    } else {
                        this.agentList = [];
                    }
                    console.log("agentList",this.agentList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.zt-block {
    background: transparent;
    box-shadow: none;
    width: 1300px;
    margin: 0 auto;
}
.thead {
    margin: 20px 0;
    .input-block {
        margin: 0 10px;
        .el-input {
            max-width:200px;
        }
        .el-button {
            width: 76px;
            left: -4px;
            position: relative;
            z-index: 2;
        }
    }
}
.list {
    .list-item {
        width: 300px;
        background: #fff;
        border: 1px solid #E6E6E6;
        border-radius: 4px;
        margin: 10px;
        .top {
            padding: 20px;
            font-size: 14px;
            line-height: 18px;
            .compName {
                font-size: 16px;
                color: #4D4D4D;
                line-height: 22px;
                margin-bottom: 8px;
            }
            .group {
                color: #4D4D4D;
                margin-bottom: 8px;
            }
            .state {
                color: #B3B3B3;
            }
        }
        .bottom {
            padding: 0 20px;
            height: 45px;
            text-align: right;
            background: #FAFAFA;
            border-top: 1px solid #E6E6E6;
        }
    }
    .list-item:nth-child(-n + 4) {
        margin-top: 0;
    }
    .list-item:nth-last-child(-n + 4) {
        margin-bottom: 0;
    }
}
</style>